<template>
  <ul>
    <li style="min-height: 100px;">&nbsp;</li>
    <li><h2 :style="'color:' + config.publicProperties.ThemeMenuItemColor">{{ x('resetPassword')
      }}</h2></li>
    <li style="min-height: 20px;">&nbsp;</li>

    <li v-if="state==='inputting'" :style="'color:' + config.publicProperties.ThemeMenuItemColor">{{
      x('loginForgottenText') }}
    </li>
    <li v-if="state==='inputting'" style="min-height: 20px;">&nbsp;</li>
    <li v-if="state==='inputting' && hasError" class="err_msg lsc-rounded-10">{{ errorMessage }}
    </li>
    <li v-if="state==='inputting'">
      <input title="username" type="text" class="lsc-input lsc-rounded-10"
             :placeholder="x('userNameDots')" name="login_email" v-model="username">
    </li>

    <li v-if="state==='inputting'">
      <button class="lsc-button lsc-rounded-10 green" @click="makeResetPasswordRequest"
              :disabled="username.length===0">
        <icon name="fa-send-o"/>{{ x('send') }}
      </button>
      &nbsp;
      <button class="lsc-button lsc-rounded-10 red" @click="close">
        <icon name="fa-close"/>{{ x('cancel') }}
      </button>
    </li>
    <li v-if="state==='done'" :style="'color:' + config.publicProperties.ThemeMenuItemColor">
      {{ x('loginForgottenEmailSent') }}
    </li>
    <li v-if="state==='done'">
      <button class="lsc-button lsc-rounded-10 green" @click="close">
        {{ x('close') }}
      </button>
    </li>
    <li style="min-height: 30px"/>
    <li class="marT20">
      <img :src="'/static/assets/gfx/'+config.publicProperties.PosmoLogoFrontPage" alt="Posmo Logo"
           width="195" height="81"/><br>
      <span class="ThemeLoginScreenText">Version: <b>{{ version }}</b></span><br>
    </li>
  </ul>
</template>

<script>
  import { mapState } from 'vuex';
  import ComponentEventNames from '@/enums/component-event-names';
  import { httpPost } from '@/classes/httpHelper';
  import translate from '../Mixins/Translate';

  export default {
    name: 'reset-password',
    mixins: [translate],
    emits: [
      ComponentEventNames.close,
    ],
    data() {
      return {
        hasError: false,
        errorMessage: '',
        username: '',
        state: 'inputting',
      };
    },
    computed: {
      version() {
        return this.config.mode === 'app' ? this.config.publicProperties.MobileWebAppVer
          : this.config.publicProperties.BackOfficeVer;
      },
      ...mapState(['config', 'user']),
    },
    methods: {
      async makeResetPasswordRequest() {
        if (this.username.length === 0) return;
        console.log('Username: ', this.username);
        try {
          await httpPost('users/password-reset-requests',
                         JSON.stringify({ username: this.username }));
          this.state = 'done';
        } catch (e) {
          const result = e.error;
          this.hasError = true;
          this.errorMessage = this.x(result.reason);
          if (result.reason === 'loginForgottenNoEmail') {
            this.errorMessage += this.config.publicProperties.ContactForgottenPass;
          }
        }
      },

      close() {
        console.log('close');
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
  h2 {
    text-transform: uppercase;
    font-size: 18px;
  }

  .err_msg {
    display: block !important;
  }

  button:disabled {
    opacity: 0.5;
  }
</style>
