/**
 * These are the events that can be broadcast from a component.
 * They are put in here to ensure that the exact same spelling is used
 * by the publisher and the subscriber.
 * @type {{}}
 */
export default {
  /** When a component is hosted by Popup.vue,
   * it emits this event to signal that the loading spinner can be removed. */
  didLoad: 'didLoad',
  /** This is used by components that support v-model.
   * It is the event that updates the model property.  */
  updateModelValue: 'update:modelValue',
  // Is used when a component updates.
  updated: 'updated',
  deleted: 'deleted',
  // Is used when a component inside a popup wants to close.
  close: 'close',
};
